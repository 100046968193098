(function ($) {
  Drupal.behaviors.ieUpgradeV1 = {
    attach: function (context) {
      var $ieUpgradeConstainer = $('.js-ie-upgrade-v1', context);
      var $ieUpgradeClose = $('.js-ie-upgrade-close', context);

      if ($.cookie('BOBBIBROWN_IE_UPGRADE_POPUP')) {
        return;
      }
      /* Colorbox does not work on less than or equal IE10
       * Display IE Upgrade Popup using basic function: append to the body.
       */
      if (navigator.userAgent.indexOf('MSIE') >= 0) {
        $ieUpgradeConstainer.show();
        $ieUpgradeClose.click(function () {
          $.cookie('BOBBIBROWN_IE_UPGRADE_POPUP', '1', {
            path: '/',
            expires: 60
          });
          $ieUpgradeConstainer.hide();
        });
      } else {
        $ieUpgradeConstainer.html('');
      }
    }
  };
})(jQuery);
